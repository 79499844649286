import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
// import Main from './Main'
import { createMember, updateMember } from '../graphql/mutations'
import { userByUserName, membersByOrganizationId, getOrganization, listSettings, ordersByOrganizationId } from '../graphql/queries'
import { v4 as uuid } from 'uuid'

// UI
import { TextInput, Textarea, Button, Spinner, SavedIcon, Checkbox, toaster } from 'evergreen-ui'
// import '../Styles/tables.css'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';
import { VerticalAlign } from 'docx'
import JSZip from 'jszip'
import axios from 'axios'
import { Document, Packer, Media, Paragraph, TextRun, AlignmentType, Table, TableRow, TableCell, BorderStyle, WidthType, HeightRule } from 'docx'
import ConvertApi from 'convertapi-js'
import { birthdayBackgroundBase64 } from './Images/docx-images-base64'

const convertApi = ConvertApi.auth(process.env.REACT_APP_CONVERTAPITOKEN)

class MemberDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            member: {
                id: this.props.id === undefined ? null : this.props.id,
                pnumber: this.props.pnumber === undefined ? null : this.props.pnumber,
                mnumber: this.props.mnumber === undefined ? null : this.props.mnumber,
                ptype: this.props.ptype === undefined ? null : this.props.ptype,
                pactive: this.props.pactive === undefined ? true : this.props.pactive,
                eforms: this.props.eforms === undefined ? true : this.props.eforms,
                pforms: this.props.pforms === undefined ? true : this.props.pforms,
                ecards: this.props.ecards === undefined ? true : this.props.ecards,
                pcards: this.props.pcards === undefined ? true : this.props.pcards,
                birthdayBooster: this.props.birthdayBooster === undefined ? false : this.props.birthdayBooster,
                eReminders: this.props.eReminders === undefined ? false : this.props.eReminders,
                fname: this.props.fname === undefined ? '' : this.props.fname,
                lname: this.props.lname === undefined ? '' : this.props.lname,
                email: this.props.email === undefined ? '' : this.props.email,
                phone: this.props.phone === undefined ? '' : this.props.phone,
                address1: this.props.address === undefined ? '' : this.props.address,
                address2: this.props.addressRemote === undefined ? '' : this.props.addressRemote,
                DOB: this.props.DOB === undefined ? '' : this.props.DOB,
                anumber: this.props.anumber === undefined ? null : this.props.anumber,
                apayment: this.props.apayment === undefined ? '' : this.props.apayment,
                notes: this.props.notes === undefined ? '' : this.props.notes,
                organizationId: this.props.organizationId === undefined ? '' : this.props.organizationId,
                searchField: this.props.searchField === undefined ? '' : this.props.searchField
            },
            organization: {
                id: '',
                name: '',
                parentOrgId: '',
                active: true,
                independent: false,
            },
            sendingCard: false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.loadPage = this.loadPage.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getFieldValues = this.getFieldValues.bind(this)
        this.saveMember = this.saveMember.bind(this)
        this.resendBirthdayCard = this.resendBirthdayCard.bind(this)
    }

    componentDidMount () {
        this.loadPage()
    }

    loadPage = async () => {
        try {
            // Check for existing setting record
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            const organization_return = await API.graphql(graphqlOperation(getOrganization, { id: orgId }))
            this.setState({
                organization: organization_return.data.getOrganization
            })
        } catch (error) {
            Bugsnag.notify(error);
            console.log('Error fetching settings: ' + error)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
          this.setState({
            member: {
                id: this.props.id === undefined ? null : this.props.id,
                pnumber: this.props.pnumber === undefined ? 0 : this.props.pnumber,
                mnumber: this.props.mnumber === undefined ? 0 : this.props.mnumber,
                ptype: this.props.ptype === undefined ? 0 : this.props.ptype,
                pactive: this.props.pactive === undefined ? true : this.props.pactive,
                eforms: this.props.eforms === undefined ? true : this.props.eforms,
                pforms: this.props.pforms === undefined ? true : this.props.pforms,
                ecards: this.props.ecards === undefined ? true : this.props.ecards,
                pcards: this.props.pcards === undefined ? true : this.props.pcards,
                birthdayBooster: this.props.birthdayBooster === undefined ? false : this.props.birthdayBooster,
                eReminders: this.props.eReminders === undefined ? false : this.props.eReminders,
                fname: this.props.fname === undefined ? '' : this.props.fname,
                lname: this.props.lname === undefined ? '' : this.props.lname,
                email: this.props.email === undefined ? '' : this.props.email,
                phone: this.props.phone === undefined ? '' : this.props.phone,
                address1: this.props.address === undefined ? '' : this.props.address,
                address2: this.props.addressRemote === undefined ? '' : this.props.addressRemote,
                DOB: this.props.DOB === undefined ? '' : this.props.DOB,
                anumber: this.props.anumber === undefined ? null : this.props.anumber,
                apayment: this.props.apayment === undefined ? '' : this.props.apayment,
                notes: this.props.notes === undefined ? '' : this.props.notes,
                organizationId: this.props.organizationId === undefined ? '' : this.props.organizationId,
                searchField: this.props.searchField === undefined ? '' : this.props.searchField
            }
          });
        }
      }

    handleChange(event) {
        const target = event.target
        const updatedMember = this.state.member
        if (target.type === "checkbox") {
            const checked = target.checked
            updatedMember[event.target.id] = checked
            this.setState({
                member: updatedMember
            })
        }
        else {
            const field = target.name
            const value = target.value
            updatedMember[field] = value
            this.setState({
                member: updatedMember
            })
        }
    }

    getFieldValues () {
        this.setState({
            member: {
                id: this.props.id === undefined ? null : this.props.id,
                pnumber: parseInt(document.getElementById("pnumber").value),
                mnumber: document.getElementById("mnumber") ? parseInt(document.getElementById("mnumber").value === '' ? null : document.getElementById("mnumber").value): null,
                ptype: document.getElementById("ptype") ? parseInt(document.getElementById("ptype").value) : null,
                pactive: document.getElementById("pactive").checked,
                eforms: document.getElementById("eforms") ? document.getElementById("eforms").checked : true,
                pforms: document.getElementById("pforms") ? document.getElementById("pforms").checked: true,
                ecards: document.getElementById("ecards") ? document.getElementById("ecards").checked : true,
                pcards: document.getElementById("pcards") ? document.getElementById("pcards").checked : true,
                birthdayBooster: document.getElementById("birthdayBooster") ? document.getElementById("birthdayBooster").checked : false,
                eReminders: document.getElementById("eReminders") ? document.getElementById("eReminders").checked : false,
                fname: document.getElementById("fname").value,
                lname: document.getElementById("lname").value,
                email: document.getElementById("email").value,
                phone: document.getElementById("phone").value,
                address1: document.getElementById("address1").value,
                address2: document.getElementById("address2").value,
                DOB: document.getElementById("DOB").value,
                anumber: document.getElementById("anumber") ? parseInt(document.getElementById("anumber").value) : null,
                apayment: document.getElementById("apayment") ? document.getElementById("apayment").value : '',
                notes: document.getElementById("notes").value,
            }
        })
    }

    saveMember = async () => {

        this.setState({ savingMember: true })

        try {
            this.getFieldValues()
            if (
                !this.state.member.pnumber || 
                this.state.member.fname === '' || 
                this.state.member.lname === '' || 
                (this.state.member.address1 === '' && this.state.member.email === '') ||
                this.state.member.DOB === ''
                ) {
                    if (!window.confirm('ID Number, first name, last name, DOB/Anniversary, and either an email or primary address are recommended before saving. Are you sure you want to proceed?')) {
                        return
                    }
                }

            var nextToken = null;
            var count = 0
            var filteredList
            var MEMBERS = []
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                    organizationId: orgId,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
            }

            // If member already exists, update it
            if (MEMBERS.some(e => e.id === this.state.member.id)) {
                // Check to make sure ID number is not already in use
                if (MEMBERS.some(e => e.pnumber === this.state.member.pnumber && e.id !== this.state.member.id)) {
                    // Get next available ID number
                    let nextId = 1;
                    while (MEMBERS.some(e => e.pnumber === nextId)) {
                        nextId++;
                    }

                    toaster.warning('ID number already in use! The next available ID is: ' + nextId.toString(), {duration: 5,})
                    return
                }
                else {
                    const updatedMember = this.state.member
                    updatedMember.searchField = this.state.member.pnumber + " " + this.state.member.fname.toLowerCase() + " " + this.state.member.lname.toLowerCase() + " " + this.state.member.pactive
                    try {
                        await API.graphql(graphqlOperation(updateMember, {input: updatedMember}))

                        // Tracking
                        toaster.success('Member updated!', {duration: 5,})
                        // this.props.reloadMain("MemberDetails");
                        } 
                    catch (error) {
                        Bugsnag.notify(error);
                        toaster.danger('Operation failed: ' + error, {duration: 5,})
                    }
                }
            }
            // If member does not exist, create it
            else {
                // Check to make sure PAP number is not already in use
                if (MEMBERS.some(e => e.pnumber === this.state.member.pnumber)) {
                    toaster.warning('PAP number already in use!', {duration: 5,})
                }
                else {
                    try {
                        //Generate new UUID for new member
                        var updatedMember = this.state.member
                        updatedMember.id = uuid()
                        updatedMember.organizationId = orgId
                        updatedMember.searchField = this.state.member.pnumber + " " + this.state.member.fname.toLowerCase() + " " + this.state.member.lname.toLowerCase() + " " + this.state.member.pactive
                        await API.graphql(graphqlOperation(createMember, {input: updatedMember}))

                        // Tracking
                        mixpanel.track('Member record created');
                        toaster.success('Member saved!', {duration: 5,})
                        this.props.reloadMain();
                    } catch (error) {
                        Bugsnag.notify(error);
                        toaster.danger('Operation failed: ' + error, {duration: 5,})
                    }
                }
            }
            this.setState({ savingMember: false })
        } catch (error) {
            this.setState({ savingMember: false })
            Bugsnag.notify(error);
            toaster.danger('Operation failed: ' + error, {duration: 5,})
        }
    }

    resendBirthdayCard = async () => {
        this.setState({ sendingCard: true });
        
        try {
            // Get current user info
            const user_info = await Auth.currentUserInfo();
            const username = user_info.username;
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }));
            const orgId = user.data.userByUserName.items[0].organizationId;

            // Get settings
            let nextToken = null;
            let count = 0;
            let SETTINGS = [];
            while (nextToken || count === 0) {
                count = 1;
                const filteredList = await API.graphql(
                    graphqlOperation(listSettings, {
                        filter: { organizationId: { eq: orgId } },
                        limit: 900,
                        nextToken: nextToken
                    })
                );
                nextToken = filteredList.data.listSettings.nextToken;
                SETTINGS = SETTINGS.concat(filteredList.data.listSettings.items);
            }

            // Get orders from this year and last year
            const date = new Date();
            const thisYear = date.getFullYear();
            const lastYear = thisYear - 1;
            nextToken = null;
            count = 0;
            let ORDERS = [];
            
            // Get this year's orders
            while (nextToken || count === 0) {
                count = 1;
                const filteredOrders = await API.graphql(
                    graphqlOperation(ordersByOrganizationId, {
                        organizationId: orgId,
                        filter: { year: { eq: thisYear.toString() } },
                        limit: 900,
                        nextToken: nextToken
                    })
                );
                nextToken = filteredOrders.data.ordersByOrganizationId.nextToken;
                ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items);
            }

            // Get last year's orders
            nextToken = null;
            count = 0;
            while (nextToken || count === 0) {
                count = 1;
                const filteredOrders = await API.graphql(
                    graphqlOperation(ordersByOrganizationId, {
                        organizationId: orgId,
                        filter: { year: { eq: lastYear.toString() } },
                        limit: 900,
                        nextToken: nextToken
                    })
                );
                nextToken = filteredOrders.data.ordersByOrganizationId.nextToken;
                ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items);
            }

            // Get all members
            nextToken = null;
            count = 0;
            let MEMBERS = [];
            while (nextToken || count === 0) {
                count = 1;
                const filteredList = await API.graphql(
                    graphqlOperation(membersByOrganizationId, {
                        organizationId: orgId,
                        limit: 900,
                        nextToken: nextToken
                    })
                );
                nextToken = filteredList.data.membersByOrganizationId.nextToken;
                MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items);
            }

            // Get gifters for this member
            const gifters = [];
            for (let order of ORDERS) {
                if (order.receiverPNumber === this.state.member.pnumber &&
                    (date - new Date(order.createdAt))/(1000*60*60*24*30.5) < 12) {
                    if (order.donorPNumber) {
                        const donor = MEMBERS.find(m => m.pnumber === order.donorPNumber);
                        if (donor) {
                            gifters.push(donor.fname + ' ' + donor.lname);
                        }
                    } else if (order.donorName) {
                        gifters.push(order.donorName);
                    }
                }
            }
            console.log('Gifters before default wishers:', gifters);

            // Add default wishers if needed
            if (gifters.length < 5 && this.state.settings?.defaultWishers?.length > 0) {
                const defaultWisherValues = this.state.settings.defaultWishers
                    .map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`));
                const defaultWishers = defaultWisherValues.map(obj => obj.label);
                gifters.unshift(...defaultWishers);
            }
            console.log('Gifters after default wishers:', gifters);

            // Generate card document
            const cardDoc = new Document();
            
            // Add background image
            const birthdayBackgroundImage = Media.addImage(
                cardDoc,
                Uint8Array.from(atob(birthdayBackgroundBase64), c => c.charCodeAt(0)),
                700,
                1000,
                {
                    floating: {
                        horizontalPosition: {
                            offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
                        },
                        verticalPosition: {
                            offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
                        },
                        behindDocument: true,
                    },
                },
            );

            // Sort gifters alphabetically by last name
            gifters.sort(function(a, b) {
                var textA = a.split(" ")[a.split(" ").length - 1];
                var textB = b.split(" ")[b.split(" ").length - 1];
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            // Create table borders
            const borders = {
                top: { style: BorderStyle.NONE, size: 1 },
                bottom: { style: BorderStyle.NONE, size: 1 },
                left: { style: BorderStyle.NONE, size: 1 },
                right: { style: BorderStyle.NONE, size: 1 },
            };

            const tableHeight = 385;  // Keep this as base height
            const singleColumnHeight = 700;  // Increased from 600
            const doubleColumnHeight = 600;  // Increased from 500
            const tripleColumnHeight = 500;  // Increased from 400

            // Different layouts based on number of gifters
            if (gifters.length <= 10) {
                // Single column layout
                cardDoc.addSection({
                    margins: {
                        top: 720,
                        right: 720,
                        bottom: 720,
                        left: 720,
                    },
                    children: [
                        new Paragraph(birthdayBackgroundImage),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                // birthdayBackgroundImage,
                            ],
                        }),
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [new TextRun({
                                text: new Date().getFullYear().toString(),
                                size: 50,
                                font: "Monotype Corsiva"
                            })]
                        }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "Happy Birthday",
                                    size: 120,
                                    font: "Monotype Corsiva",
                                    break: 0,
                                }),
                                new TextRun({
                                    text: this.state.member.fname + ' ' + this.state.member.lname,
                                    size: 100,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                                new TextRun({
                                    text: "Best wishes from your friends at",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                                new TextRun({
                                    text: SETTINGS[0]?.chapterName || "WishWell",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                                new TextRun({
                                    text: "",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                            ]
                        }),
                        new Table({
                            alignment: AlignmentType.CENTER,
                            rows: gifters.map(name => new TableRow({
                                children: [
                                    new TableCell({
                                        borders,
                                        width: { size: 500, type: WidthType.DXA },
                                        children: [new Paragraph({
                                            alignment: AlignmentType.CENTER,
                                            children: [
                                                new TextRun({
                                                    text: name,
                                                    size: 72,
                                                    font: "Monotype Corsiva",
                                                }),
                                            ]
                                        })],
                                    }),
                                ],
                                height: { height: singleColumnHeight, rule: HeightRule.EXACT },
                            })),
                            width: { size: 60, type: WidthType.PERCENTAGE },
                        })
                    ],
                });
            } else if (gifters.length <= 20) {
                // Two column layout
                const rows = [];
                for (let i = 0; i < Math.ceil(gifters.length / 2); i++) {
                    const k = i * 2;
                    rows.push(new TableRow({
                        children: [
                            new TableCell({
                                borders,
                                width: { size: 700, type: WidthType.DXA },
                                children: [new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [new TextRun({
                                        text: gifters[k],
                                        size: 56,
                                        font: "Monotype Corsiva",
                                    })],
                                })],
                            }),
                            new TableCell({
                                borders,
                                width: { size: 700, type: WidthType.DXA },
                                children: [new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [new TextRun({
                                        text: gifters[k + 1] || "",
                                        size: 56,
                                        font: "Monotype Corsiva",
                                    })],
                                })],
                            }),
                        ],
                        height: { height: doubleColumnHeight, rule: HeightRule.EXACT },
                    }));
                }

                cardDoc.addSection({
                    margins: {
                        top: 720,
                        right: 720,
                        bottom: 720,
                        left: 720,
                    },
                    children: [
                        new Paragraph(birthdayBackgroundImage),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                // birthdayBackgroundImage,
                            ],
                        }),
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [new TextRun({
                                text: new Date().getFullYear().toString(),
                                size: 50,
                                font: "Monotype Corsiva"
                            })]
                        }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "Happy Birthday",
                                    size: 120,
                                    font: "Monotype Corsiva",
                                    break: 0,
                                }),
                                new TextRun({
                                    text: this.state.member.fname + ' ' + this.state.member.lname,
                                    size: 100,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                                new TextRun({
                                    text: "Best wishes from your friends at",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                                new TextRun({
                                    text: SETTINGS[0]?.chapterName || "WishWell",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                                new TextRun({
                                    text: "",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                    break: 1,
                                }),
                            ]
                        }),
                        new Table({
                            alignment: AlignmentType.CENTER,
                            rows: rows,
                            width: { size: 80, type: WidthType.PERCENTAGE },
                        })
                    ],
                });
            } else {
                // Three column layout with page breaks
                const rows = [];
                for (let i = 0; i < Math.ceil(gifters.length / 3); i++) {
                    const k = i * 3;
                    
                    // Add page break and header if needed
                    if (i % 13 === 0 && i !== 0) {
                        cardDoc.addSection({
                            margins: {
                                top: 720,
                                right: 720,
                                bottom: 720,
                                left: 720,
                            },
                            children: [
                                new Paragraph(birthdayBackgroundImage),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "More friends...",
                                            size: 50,
                                            font: "Monotype Corsiva",
                                        }),
                                    ]
                                }),
                                new Table({
                                    alignment: AlignmentType.CENTER,
                                    rows: rows.splice(0, rows.length),
                                    width: { size: 90, type: WidthType.PERCENTAGE },
                                })
                            ],
                        });
                    }

                    rows.push(new TableRow({
                        children: [
                            new TableCell({
                                borders,
                                width: { size: 700, type: WidthType.DXA },
                                children: [new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [new TextRun({
                                        text: gifters[k],
                                        size: 45,
                                        font: "Monotype Corsiva",
                                    })],
                                })],
                            }),
                            new TableCell({
                                borders,
                                width: { size: 700, type: WidthType.DXA },
                                children: [new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [new TextRun({
                                        text: gifters[k + 1] || "",
                                        size: 45,
                                        font: "Monotype Corsiva",
                                    })],
                                })],
                            }),
                            new TableCell({
                                borders,
                                width: { size: 700, type: WidthType.DXA },
                                children: [new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [new TextRun({
                                        text: gifters[k + 2] || "",
                                        size: 45,
                                        font: "Monotype Corsiva",
                                    })],
                                })],
                            }),
                        ],
                        height: { height: tripleColumnHeight, rule: HeightRule.EXACT },
                    }));
                }

                // Add final section with remaining rows
                cardDoc.addSection({
                    margins: {
                        top: 720,
                        right: 720,
                        bottom: 720,
                        left: 720,
                    },
                    children: [
                        new Paragraph(birthdayBackgroundImage),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: "More friends...",
                                    size: 50,
                                    font: "Monotype Corsiva",
                                }),
                            ]
                        }),
                        new Table({
                            alignment: AlignmentType.CENTER,
                            rows: rows,
                            width: { size: 90, type: WidthType.PERCENTAGE },
                        })
                    ],
                });
            }

            // Convert to PDF
            const docBlob = await Packer.toBlob(cardDoc);
            let file = new File([docBlob], `${this.state.member.fname} Birthday Card.docx`, 
                {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});

            let params = convertApi.createParams();
            params.add('file', file);
            let result = await convertApi.convert('docx', 'pdf', params);
            
            let url = result.files[0].Url;
            let response = await axios.get(url, {responseType: 'arraybuffer'});
            let pdfData = new Buffer.from(response.data, 'binary').toString('base64');

            // Helper function to validate email
            const isValidEmail = (email) => {
                return email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            };

            // Send email
            const options = {
                from: `${SETTINGS[0]?.chapterName || 'WishWell'} <wishwell@onegiftfoundation.org>`,
                replyTo: SETTINGS[0]?.orgEmail,
                to: [
                    isValidEmail(this.state.member.email) ? this.state.member.email : null,
                    isValidEmail(username) ? username : null
                ].filter(Boolean).join(','),
                subject: `Your ${SETTINGS[0]?.chapterName || 'WishWell'} Birthday Card`,
                html: `<p>${this.state.member.fname},</p>
<p>Here's your ${SETTINGS[0]?.chapterName || 'WishWell'} birthday card! We hope you enjoy it.</p>
<p>Happy Birthday!</p>
<p>Your ${SETTINGS[0]?.chapterName || 'WishWell'} Team</p>`,
                attachments: [{
                    filename: `${this.state.member.fname} Birthday Card.pdf`,
                    content: pdfData,
                    contentType: 'application/pdf',
                    encoding: 'base64'
                }]
            };

            // Check if we have any valid recipients before sending
            if (!options.to) {
                throw new Error('No valid email addresses to send to');
            }

            const apiName = 'wishwellapi';
            const path = '/wishwellapi/sendEmail';
            
            this.setState({ sendingCard: true });
            await API.post(apiName, path, { body: { options } });
            
            toaster.success('Birthday card sent!', {
                duration: 5,
                id: 'birthday-card-toast' // Add unique ID to prevent duplicate toasts
            });
            
        } catch (error) {
            console.error(error);
            Bugsnag.notify(error);
            toaster.danger('Failed to send birthday card: ' + error.message, {
                duration: 5,
                id: 'birthday-card-error-toast'
            });
        } finally {
            this.setState({ sendingCard: false });
        }
    }

    render() {
        
        return (
            <div>
            <div style={{ textAlign: "right", display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                <br></br>
                <form>
                    <table className="responsive-table" style={{borderSpacing: '10px'}}><tbody>
                        <tr><td colSpan={2}>
                            <tr>
                                <td style={{ width: '250px' }}><label htmlFor="fname">First Name* </label></td>
                                <td><TextInput type="text" id="fname" name="fname" value={this.state.member.fname} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="pnumber">ID Number* </label></td>
                                <td><TextInput type="number" id="pnumber" name="pnumber" value={this.state.member.pnumber} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="lname">Last Name* </label></td>
                                <td><TextInput type="text" id="lname" name="lname" value={this.state.member.lname} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="DOB">Birthday* </label></td>
                                <td><TextInput type="date" id="DOB" name="DOB" value={this.state.member.DOB} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="email">E-mail </label></td>
                                <td><TextInput type="email" id="email" name="email" value={this.state.member.email} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="address1">Address </label></td>
                                <td><TextInput placeholder="123 Anywhere St, Boynton Beach, FL 33437" type="text" id="address1" name="address1" value={this.state.member.address1} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="phone">Phone </label></td>
                                <td><TextInput type="text" id="phone" name="phone" value={this.state.member.phone} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="address2">Address 2 </label></td>
                                <td><TextInput type="text" id="address2" name="address2" value={this.state.member.address2} onChange={this.handleChange}></TextInput></td>  
                            </tr>
                            {/* TODO: remove hard-coded pap custom field filter */}
                            {this.state.organization.parentOrgId === '859d0bb4-21c4-4c0d-9804-126093d78552' && <div>
                            <tr>
                                <td><label htmlFor="ptype">PAP Type (1-5)* </label></td>
                                <td><TextInput type="number" id="ptype" name="ptype" value={this.state.member.ptype} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="mnumber">Member Number </label></td>
                                <td><TextInput type="number" id="mnumber" name="mnumber" value={this.state.member.mnumber} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="anumber">Angel Number </label></td>
                                <td><TextInput type="number" id="anumber" name="anumber" value={this.state.member.anumber} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="apayment">Last Angel Payment </label></td>
                                <td><TextInput disabled={this.state.member.anumber === 0 ? true : false} type="date" id="apayment" name="apayment" value={this.state.member.apayment} onChange={this.handleChange}></TextInput></td>    
                            </tr></div>}
                            <tr>
                                <td><label htmlFor="notes">Notes </label></td>
                                <td><Textarea type="text" id="notes" name="notes" value={this.state.member.notes} onChange={this.handleChange}></Textarea></td>
                            </tr>
                        </td>
                        <td className="responsive-table-checkbox">
                            <tr>
                                <td><label htmlFor="pactive">Active?</label></td>
                                <td><Checkbox type="checkbox" id="pactive" name="pactive" checked={this.state.member.pactive} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="birthdayBooster">Birthday Booster</label></td>
                                <td><Checkbox type="checkbox" id="birthdayBooster" name="birthdayBooster" checked={this.state.member.birthdayBooster} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="eReminders">Email Special Day Reminders</label></td>
                                <td><Checkbox type="checkbox" id="eReminders" name="eReminders" checked={this.state.member.eReminders} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="pforms">Mail Order Forms</label></td>
                                <td><Checkbox type="checkbox" id="pforms" name="pforms" checked={this.state.member.pforms} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="eforms">Email Order Form</label></td>
                                <td><Checkbox type="checkbox" id="eforms" name="eforms" checked={this.state.member.eforms} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="pcards">Mail Cards</label></td>
                                <td><Checkbox type="checkbox" id="pcards" name="pcards" checked={this.state.member.pcards} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="ecards">Email Cards</label></td>
                                <td><Checkbox type="checkbox" id="ecards" name="ecards" checked={this.state.member.ecards} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                        </td>
                        </tr>
                    </tbody></table>
                </form>
                <br></br>
            </div>
            <div style={{ textAlign: "left", display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px', paddingBottom: '50px' }}>
                <Button iconBefore={SavedIcon} onClick={this.saveMember} appearance='primary' intent='none'>
                    {this.state.savingMember && <Spinner size={16} marginRight={8} />}
                    Save Member
                </Button>
                <Button 
                    onClick={this.resendBirthdayCard} 
                    appearance='default' 
                    intent='none'
                    disabled={!this.state.member.email || !this.state.member.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
                >
                    {this.state.sendingCard && <Spinner size={16} marginRight={8} />}
                    Re-send Latest Birthday Card
                </Button>
            </div>
            </div>
        )
    }
}

export default MemberDetails